<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="d-flex">
      <h1 class="title mt-0 fs-b fs-2500 color-pr">
        <span class="ri-arrow-left-line" @click="toLogin()" />
        Mot de passe oublié
      </h1>
    </div>

    <div class="row container">
      <div class="col-sm-4">
        <img
          src="../assets/img/mail.svg"
          class="mt-4"
          aria-hidden="true"
          alt="icone d'un mail"
        />
      </div>
      <div class="col-sm-8">
        <span class="title-bloc">Mot de passe oublié</span><br />
        <span class="text-info"
          >Veuillez entrer votre identifiant (adresse courriel nominative)
          <br />
          dans le champ le champ ci-dessous</span
        >

        <div class="form-mail">
          <label class="text-label" for="mail"
            >Identifiant (adresse courriel nominative) *</label
          >
          <input class="fr-input" id="mail" v-model="mail" />
        </div>

        <div
          v-if="msgErreur && msgErreur.length !== 0"
          class="fr-alert fr-alert--error ri-alert-line"
        >
          <p class="fr-alert__title">{{ msgErreur }}</p>
        </div>

        <div
          v-if="msgSuccess && msgSuccess.length !== 0"
          class="fr-alert fr-alert--success"
        >
          <p class="fr-alert__title">{{ msgSuccess }}</p>
        </div>

        <div class="form-button">
          <button class="col btn-annuler" @click="annuler()">Annuler</button>
          <button class="col btn" @click="envoyer(mail)">Envoyer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import store from '../store';
import { validateEmail } from '../utils/methodsUtils';

export default {
  data() {
    return {
      fullname: store.state.user.fullname,
      email: store.state.user.email,
      mail: undefined,
      msgErreur: '',
      msgSuccess: ''
    };
  },
  mounted() {},
  methods: {
    annuler() {
      const html = `<p class="fs-875">Etes-vous sûr de vouloir abandonner ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.mail = '';
          this.msgErreur = '';
          this.toLogin();
        }
      });
    },
    validate(mail) {
      this.msgSuccess = '';
      this.msgErreur = '';
      if (mail) {
        this.msgErreur = "L'identifiant doit être un email.";
        const result = validateEmail(mail);
        if (result === true) {
          this.msgErreur = '';
        }

        return result;
      } else {
        this.msgErreur = '';
        return false;
      }
    },
    envoyer(mail) {
      if (this.validate(mail)) {
        const compte = {
          user: {
            username: mail,
            realm: ''
          },
          properties: []
        };
        this.$compteUtilisateurService
          .recoverPasswordByMail(compte)
          .then((res) => {
            if (res.status === 200 && res.data.resultat == 202) {
              const html = `<p class="fs-875">Les informations de récupération du mot de passe ont été envoyées à l'adresse courriel enregistrée avec le compte ${mail}</p>`;
              this.setContent({ html, action: 'info' });
              this.showPopup();
              this.emitter.once('popup', () => {
                this.toLogin();
              });
            }
          })
          .catch((err) => {
            this.msgErreur = `Une erreur est survenue.`;
            console.error('erreur', err);
          });
      }
    },

    toLogin() {
      this.$router.push({ name: 'Mon compte' });
    },
    ...mapActions(['showPopup', 'setContent'])
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: $base-color;
  display: flex;
}

.ri-arrow-left-line {
  width: 18rem;
  color: black;
  cursor: pointer;
}

.bloc {
  width: auto;
  display: flex;
  padding: 1rem;
}

.container {
  width: 65%;
  padding: 3%;
  padding-right: 2%;
  border: 2px solid #dddddd;
  margin: 2%;
  margin-left: 24%;
}

.fr-fi-user-line::before {
  font-size: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 0 2px #888;
  padding: 0.3em 0.3em;
}

.fr-fi-user-line {
  font-size: 2.5rem;
  padding: 2%;
}

.title-bloc {
  font-size: 20px;
}

.text-label {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.text-info {
  color: var(--g600) !important;
  font-size: 14px;
}

.form-mail {
  display: grid;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 70%;
}

.fr-input {
  box-shadow: inset 0 -2px 0 0 var(--g600);
}

.fr-input:focus {
  z-index: 0;
  box-shadow: 0 0 0 0 transparent inset;
}

.input-number {
  display: flex;
}

.indicator {
  width: min-content;
}

.btn-annuler {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 14px;
  width: 100%;
  margin-left: 4rem;
  margin-right: 1rem;
}
.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.btn:hover {
  background-color: #1212ff;
}

.input-eye {
  width: 19px;
  height: 16px;
  bottom: 30px;
  right: -90%;
  position: initial;
  margin-left: 90%;
  margin-top: -30px;
  color: $base-color;
  z-index: 9999;
}

@supports (background: -webkit-named-image(i)) {
  .fr-input:focus + .input-eye {
    margin-left: 82%;
    right: -82%;
  }
}

.form-button {
  display: flex;
  width: 60%;
  margin-left: 10%;
  padding-top: 0.5rem;
}

.ri-close-line {
  color: #9f3a38;
}

.ri-check-line {
  color: green;
}

.form-error {
  white-space: normal;
  background: #ffffff !important;
  color: #9f3a38 !important;
  padding-bottom: 1rem;
}

.fr-alert--error {
  width: 70%;
}

.fr-alert--success {
  width: 70%;
}
</style>
